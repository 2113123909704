import PropTypes from 'prop-types';
import { useRef, forwardRef } from 'react';
import { SnackbarProvider, SnackbarContent } from 'notistack';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import { Box, Paper, styled } from '@mui/material';
//
import Iconify from './Iconify';
import { IconButtonAnimate } from './animate';

// ----------------------------------------------------------------------

const CustomSnackBarContent = styled(SnackbarContent)(({ theme, bgColor, color }) => ({
  borderRadius: theme.shape.borderRadius,
  width: '100%',
  padding: theme.spacing(1),
  margin: theme.spacing(0.25, 0),
  boxShadow: theme.customShadows.z8,
  color,
  backgroundColor: bgColor,
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    minWidth: 240,
  },
}));

const StyledSnackbar = forwardRef(({ id, message, variant, action, iconVariant, ...props }, ref) => {
  console.log('🚀 ~ StyledSnackbar ~ action:', action);
  console.log('🚀 ~ StyledSnackbar ~ props:', props);
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  const bgColorVariants = {
    default: theme.palette.grey[isLight ? 900 : 0],
    success: theme.palette.background.paper,
    error: theme.palette.background.paper,
    warning: theme.palette.background.paper,
    info: theme.palette.background.paper,
  };

  const colorVariants = {
    default: theme.palette.grey[isLight ? 0 : 800],
    success: theme.palette.text.primary,
    error: theme.palette.text.primary,
    warning: theme.palette.text.primary,
    info: theme.palette.text.primary,
  };

  const bgColor = bgColorVariants[variant] || theme.palette.background.paper;
  const color = colorVariants[variant] || theme.palette.text.primary;

  return (
    <CustomSnackBarContent ref={ref} role="alert" bgColor={bgColor} color={color} {...props}>
      {iconVariant[variant]}
      <Box
        sx={{
          flexGrow: 1,
          px: 1.5,
          fontWeight: theme.typography.fontWeightMedium,
          fontSize: '0.875rem',
        }}
      >
        {message}
      </Box>
      <Box sx={{ marginRight: 0, color: theme.palette.action.active, '& svg': { width: 20, height: 20 } }}>
        {action && action(id)}
      </Box>
    </CustomSnackBarContent>
  );
});

// ----------------------------------------------------------------------

NotistackProvider.propTypes = {
  children: PropTypes.node,
};

export default function NotistackProvider({ children }) {
  const notistackRef = useRef(null);

  const onClose = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      ref={notistackRef}
      dense
      maxSnack={5}
      preventDuplicate
      autoHideDuration={3000}
      variant="success" // Set default variant
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      Components={{
        default: StyledSnackbar,
        success: StyledSnackbar,
        error: StyledSnackbar,
        warning: StyledSnackbar,
        info: StyledSnackbar,
      }}
      iconVariant={{
        info: <SnackbarIcon icon={'eva:info-fill'} color="info" />,
        success: <SnackbarIcon icon={'eva:checkmark-circle-2-fill'} color="success" />,
        warning: <SnackbarIcon icon={'eva:alert-triangle-fill'} color="warning" />,
        error: <SnackbarIcon icon={'eva:alert-circle-fill'} color="error" />,
      }}
      action={(key) => (
        <IconButtonAnimate size="small" onClick={onClose(key)} sx={{ p: 0.5 }}>
          <Iconify icon={'eva:close-fill'} />
        </IconButtonAnimate>
      )}
    >
      {children}
    </SnackbarProvider>
  );
}

// ----------------------------------------------------------------------

SnackbarIcon.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error']),
};

function SnackbarIcon({ icon, color }) {
  return (
    <Box
      component="span"
      sx={{
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      }}
    >
      <Iconify icon={icon} width={24} height={24} />
    </Box>
  );
}
